:root {
    font-size: 16px;
}

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
}

body {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 0, 0.1);
    color: #1c384b;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 3rem !important;
}

h2 {
    font-size: 2rem !important;
}

h3 {
    font-size: 1.5rem !important;
}

h4, h5, h6 {
    font-size: 1rem !important;
}

h1, h2 {
    font-weight: 700 !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Baloo Bhaijaan 2', sans-serif !important;
}