:root {
  font-size: 16px;
}

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background-color: #0000001a;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 1rem;
}

body {
  color: #1c384b;
  background-color: #0000001a;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

h1 {
  font-size: 3rem !important;
}

h2 {
  font-size: 2rem !important;
}

h3 {
  font-size: 1.5rem !important;
}

h4, h5, h6 {
  font-size: 1rem !important;
}

h1, h2 {
  font-weight: 700 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Baloo Bhaijaan 2", sans-serif !important;
}

.B2wDDG_app {
  flex-direction: row;
  gap: 1rem;
  height: 100%;
  display: flex;
}

.B2wDDG_app .B2wDDG_sidebar {
  flex-direction: column;
  flex-basis: 15rem;
  margin: 1rem 0 1rem 1rem;
  padding: 1rem;
  display: flex;
}

.B2wDDG_app .B2wDDG_sidebar .B2wDDG_logo {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.B2wDDG_app .B2wDDG_sidebar .B2wDDG_logo svg:first-child {
  margin: 0 auto;
}

.B2wDDG_app .B2wDDG_sidebar nav {
  flex: 1;
  margin-top: 1rem;
  overflow: auto;
}

.B2wDDG_app .B2wDDG_sidebar nav a {
  color: inherit;
}

.B2wDDG_app .B2wDDG_sidebar > footer {
  text-align: center;
  margin-top: 1rem;
}

.B2wDDG_app .B2wDDG_sidebar > footer img {
  max-width: 100%;
  height: auto;
}

.B2wDDG_app .B2wDDG_main {
  border-radius: .5rem;
  flex: 1;
  margin: 1rem 1rem 1rem 0;
  overflow: auto;
}

.B2wDDG_dashboard {
  padding: 1rem;
}

.B2wDDG_dashboard-blurb {
  margin: 1rem;
  padding: 1rem;
}

.B2wDDG_dashboard-tools {
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: 1rem;
  margin: 1rem 1rem .5rem;
  display: grid;
}

.B2wDDG_dashboard-tools a {
  color: inherit;
  background-color: #fff;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  transition: all .1s ease-in-out;
  text-transform: none !important;
}

.B2wDDG_dashboard-tools a:hover {
  background-color: #f5f5f5;
}

.B2wDDG_tool {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  display: flex;
}

.B2wDDG_tool h2 {
  margin-bottom: 1rem;
}

.dvP7wa_tool textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
  border-radius: 5px;
  outline: 1px solid silver;
  width: 100%;
  max-width: 10rem;
  height: 10rem;
  padding: .5rem;
}

.dvP7wa_tool .dvP7wa_textAreaBox, .dvP7wa_tool .dvP7wa_answerBox {
  margin-bottom: 1rem;
}

.ZoAl2a_tool {
  width: 100%;
}

.ZoAl2a_fileTypeBox, .ZoAl2a_durationBox {
  margin-bottom: 1rem;
}

.ZoAl2a_parametersBox {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.ZoAl2a_duration {
  width: 100%;
  max-width: 15rem;
}

.ZoAl2a_bitDepth {
  width: 100%;
  max-width: 5rem;
}

.ZoAl2a_sampleRate, .ZoAl2a_channelCount {
  width: 100%;
  max-width: 10rem;
}

.ZoAl2a_bitrate {
  width: 100%;
  max-width: 5rem;
}

/*# sourceMappingURL=index.4691af6f.css.map */
